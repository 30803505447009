.App {
  text-align: center;
}

.App-header {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(43, 43, 43);
}

.website-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  border-radius: 8px;
  width: 70%;
  padding: 16px;
  box-shadow: 1px 3px 9px 1px #88888826;
}

img {
  max-height: 100px;
  max-width: 80%;
}

@media only screen and (min-width: 800px) {
  .website-container {
    width: 50%;
  }
}

footer {
  font-size: calc(3px + 1vmin);;
  opacity: 0.5;
}
